import React, {createRef, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, /*withStyles*/ } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import {Grid, List, ListItem} from '@material-ui/core';
import 'sweetalert2/src/sweetalert2.scss';
import configData from '../../config';
import { ApiGet, httpsConv } from '../../utils';
import CoinColletion from '../CoinCollection';
import "./HuntQuestion.scss"

export default function HuntQuestion() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      flexGrow: 1
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: '20px',
      // marginBottom: theme.spacing(1),
    },
  }));

  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const { huntid } = useParams();
  const [hunt, setHunt] = useState();
  const [questions, setQuestions] = useState([]);
  const [correct, setCorrect] = useState([]);
  const [yourAnswer, setYourAnswer] = useState([]);
  const [openAnswerPopup, setOpenAnswerPopup] = useState(false);
  const [answersPage, setAnswersPage] = useState(false);
  const [audioState, setAudioState] = useState(false);
  const audioRef = createRef();
  const [audioSrc, setAudioSrc] = useState(null);

  useEffect(() => {
    setLoading(true);

    (async () => {
      const getProgressData = () => {
        try {
          let progressData = JSON.parse(localStorage.getItem('huntQuestionProgress'));
          if (progressData.huntid === huntid) {
            return progressData;
          } else {
            localStorage.removeItem('huntQuestionProgress');
          }
        } catch (e) {
          return null;
        }
      };

      let res = await ApiGet(`${configData.apiUrl}/hunts/${huntid}.xml`);

      var convert = require('xml-js');
      var result1 = convert.xml2json(res.data, { compact: true, spaces: 2 });
      //console.log('result1', JSON.parse(result1).hunt);
      let hunt = JSON.parse(result1).hunt;
      setHunt(hunt);
      let questions = Array.isArray(hunt.questions.question)
        ? hunt.questions.question
        : [hunt.questions.question];
      //console.log("questions", questions);
      setQuestions(questions);
      setSteps(questions.map((obj, index) => 'Question ' + (index + 1)));

      let progressData = getProgressData();
      if (progressData) {
        setCorrect(progressData.correct);
        setActiveStep(+progressData.activeStep);

        if (progressData.activeStep === steps.length) {
          localStorage.removeItem('huntQuestionProgress');
        }
      } else {
        setCorrect(Array(questions.length).fill(false));
        setProgressData(huntid, activeStep, Array(questions.length).fill(false));
      }
      setLoading(false);

      setAudioSrc(questions[activeStep].audio_hint?._text && (configData.systemUrl+questions[activeStep].audio_hint._text));

    })();
  }, [huntid]); /// missing dependencies needs refactor!!!

  const setProgressData = (huntid, activeStep, correct) => {
    console.log('Set progress data', activeStep, correct);
    localStorage.setItem('huntQuestionProgress', JSON.stringify({ huntid, activeStep, correct }));
  }

  const handleNext = () => {
    audioRef?.current?.pause();
    setOpenAnswerPopup(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setAudioSrc(questions[activeStep].audio_hint?._text && (configData.systemUrl+questions[activeStep].audio_hint._text ));
    //console.log('activeStep', activeStep);
    if ((activeStep+1) === questions.length) {
      let rewardData = {
        huntid,
        huntTitle: hunt.hunt_title._text,
        score: getRewardScore()
      };

      let savedRewards = [];
      try {
        savedRewards = JSON.parse(localStorage.getItem('savedRewards')) || [];
      } catch (e) {}

      let rewardExistIndex = savedRewards.findIndex((reward) => reward.huntid === huntid);
      if (rewardExistIndex > -1) {
        savedRewards.splice(rewardExistIndex, 1);
      }
      savedRewards.push(rewardData);
      localStorage.setItem('savedRewards', JSON.stringify(savedRewards));
    }
  };

  const handleComplete = () => {
    localStorage.removeItem('huntQuestionProgress');
    const item = localStorage.getItem('mytrails');
    const data = {
      hunt: hunt,
      answers: yourAnswer,
    };
    // console.log("Data>>>>>>",data)
    if (!item) {
      localStorage.setItem('mytrails', JSON.stringify([data]));
    } else {
      let items = JSON.parse(item);
      // console.log("items>>>>>>>",items)
      let flag = 0;
      for (let i = 0; i < items.length; i++) {
        let obj = items[i];
        if (obj.hunt.hunt_id === data.hunt.hunt_id) {
          items.splice(i, 1, data);
          flag = 1;
        }
      }
      if (flag === 0) {
        items.push(data);
      }
      // console.log("Pushed item>>>",items)
      localStorage.setItem('mytrails', JSON.stringify(items));
    }
    history.push('/');
  };

  const submitanswer = (e, userAnswer, activeStep) => {
    const correctAnswer = parseInt(questions[activeStep].correct_answer._text);
    // console.log("usewransewewre", userAnswer);
    // console.log("correcjy>>>?", correctAnswer);
    setYourAnswer(yourAnswer.concat([userAnswer]));
    setOpenAnswerPopup(true);
    if (userAnswer === correctAnswer) {
      correct.splice(activeStep, 1, true);
      // console.log("CORRRR>>>>>>>>>>",correct)
      setCorrect(correct);
    }
    setAudioSrc(questions[activeStep].answer_audio?.text && (configData.systemUrl+questions[activeStep].answer_audio._text));
    setAudioState(false);

    if (activeStep + 1 === questions.length) {
      localStorage.removeItem('huntQuestionProgress');
    } else {
      setProgressData(huntid, activeStep + 1, correct);
    }
  };

  const getCorrectAnswerText = (answerNumber, questionIndex = '') => {
    // eslint-disable-next-line default-case
    if (+questionIndex >= questions.length) return '';
    questionIndex = questionIndex !== '' ? questionIndex : activeStep;
    switch (answerNumber.toString()) {
      case '1': return questions[+questionIndex].option_a._text;
      case '2': return questions[+questionIndex].option_b._text;
      case '3': return questions[+questionIndex].option_c._text;
      case '4': return questions[+questionIndex].option_d._text;
    }
  }

  const getRewardScore = () => correct.filter((answer) => answer === true).length / questions.length;

  const getRewardMessage = () => {
    let score = getRewardScore();
    if (score > 0.8) {
      return hunt.hunt_gold_reward._text;
    } else if (score >= 0.5) {
      return hunt.hunt_silver_reward._text;
    } else {
      return hunt.hunt_bronze_reward._text;
    }
  };

  const getRewardImage = () => {
    let score = getRewardScore();
    if (score > 0.8) {
      return require('../../../src/images/hunts/gold.svg').default;
    } else if (score >= 0.5) {
      return require('../../../src/images/hunts/silver.svg').default;
    } else {
      return require('../../../src/images/hunts/bronze.svg').default;
    }
  };

  return (
    <div className="container-fluid maxWidhCointainer">
      {loading ? (
        <strong>Loading...</strong>
      ) : (
        <div className={classes.root}>
          <div>
            {steps.length !== 0 &&
              (activeStep === steps.length ? (
                <div>
                  {
                    !answersPage ? <div className="mt-3 d-flex justify-content-center hunt-title text-center">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h1>My Reward</h1>
                        </Grid>

                        <Grid item xs={12}>
                          <img style={{ width: '264px' }} className="d-inline-block" src={getRewardImage()} alt="Reward Coin"/>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="rewardMessageContainer">
                            <h4 className="rewardMessage">{ getRewardMessage() }</h4>
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                              onClick={() => setAnswersPage(true)}
                              className="rewardButtons w-100 text-capitalize">My Answers</Button>
                        </Grid>

                        <Grid item xs={6}>
                          <Button onClick={() => history.push('/saved-rewards')}
                                  className="rewardButtons w-100 text-capitalize">My Rewards</Button>
                        </Grid>

                        <Grid item xs={12}>
                          <Button className="rewardButtons w-100 text-capitalize" onClick={() => history.push('/')}>Close Trail</Button>
                        </Grid>
                      </Grid>
                    </div> : null
                  }

                  {
                    answersPage ? <>

                      <div className="mt-3 d-flex justify-content-center hunt-title text-center">
                        <Grid container>
                          <Grid item xs={12}>
                            <h1 className="text-center">My Answers</h1>
                          </Grid>
                        </Grid>
                      </div>

                      <List container spacing={3}>
                        {questions &&
                        questions.length > 0 &&
                        questions.map((obj, index) => {
                          return (
                              /*<Grid item xs={12} md={6} key={index}>
                                <Card
                                    key={index}
                                    className={classes.instructions + 'col-mg-6'}
                                >
                                  <CardContent>
                                    <Typography
                                        className="question-font-size mt-2"
                                        gutterBottom
                                        variant="h4"
                                        component="h4"
                                    >
                                      {obj.location_clue._text}
                                    </Typography>
                                    {obj.picture_hint?._text && (
                                        <CardMedia
                                            component="img"
                                            alt="Question Hint Image"
                                            height="250"
                                            style={{ width: 'auto', margin: 'auto' }}
                                            image={httpsConv(configData.systemUrl+obj.picture_hint._text)}
                                            title="Question Hint Image"
                                        />
                                    )}

                                    <Typography
                                        className="question-font-size"
                                        gutterBottom
                                        variant="h4"
                                        component="h4"
                                        style={{
                                          textAlign: 'justify',
                                          marginTop: '200px',
                                        }}
                                    >
                                      Question {index + 1}:{' '}
                                      {obj.clue._text.split('\\n').join(' ')}
                                    </Typography>
                                    <div className="pt-2">
                                      <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                          style={{
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                      >
                                        Your Answer: {yourAnswer[index]}
                                        {yourAnswer[index] ===
                                        parseInt(obj.correct_answer._text) ? (
                                            <i
                                                className="fa fa-check"
                                                style={{
                                                  color: 'green',
                                                  fontSize: '24px',
                                                }}
                                                aria-hidden="true"
                                            ></i>
                                        ) : (
                                            <i
                                                className="fa fa-times"
                                                style={{
                                                  color: 'red',
                                                  fontSize: '24px',
                                                }}
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                      </Typography>
                                      <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                          style={{ textAlign: 'center' }}
                                      >
                                        Correct Answer: {obj.correct_answer._text}
                                      </Typography>
                                    </div>
                                    <div className="pt-5">
                                      <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                          style={{ textAlign: 'center' }}
                                      >
                                        Solution:{' '}
                                        {obj.correct_answer._text === '1'
                                            ? obj.option_a._text
                                            : obj.correct_answer._text === '2'
                                                ? obj.option_b._text
                                                : obj.option_c._text}
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>*/
                              <>
                                <Divider />
                                <ListItem>
                                  <Grid item xs={2} md={2}>
                                    <CardContent>
                                      <img className="answerDetailImage" src={require(`../../images/${correct[index] ? 'Correct Answer.svg' : 'Wrong Answer.svg'}`).default} alt={'sdf'}/>
                                    </CardContent>
                                  </Grid>

                                  <Grid item xs={10}>
                                    <CardContent>
                                      <div className="d-flex flex-column justify-content-between detailedQA">
                                        <span className="pb-4">Q. { obj.clue._text }</span>
                                        <span>A. { getCorrectAnswerText(obj.correct_answer._text, index) }</span>
                                      </div>
                                    </CardContent>
                                  </Grid>
                                </ListItem>
                              </>
                          );
                        })}
                        <Divider />
                      </List>
                      {/*<Card className={classes.instructions}>
                        <CardContent>
                          <Typography
                              gutterBottom
                              variant="h2"
                              component="h2"
                              style={{ textAlign: 'center' }}
                          >
                            Your Score:{' '}
                            {correct.filter((obj) => obj === true).length} /{' '}
                            {questions.length}
                          </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                          <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              onClick={handleComplete}
                          >
                            Save The Trail
                          </Button>
                        </CardActions>
                      </Card>*/}
                    </> : null
                  }
                </div>
              ) : (
                <div>
                  {hunt && (
                      <>
                        <div className="mt-3 d-flex justify-content-center hunt-title">
                          <h1>{hunt.hunt_title._text}</h1>
                        </div>
                      </>
                  )}

                  {/*!openAnswerPopup &&*/ <CoinColletion answers={correct} current={activeStep + (openAnswerPopup ? 1:0)}/>}

                  <div className="questionBanner d-flex align-items-center">
                    <Typography
                      className="pl-2 question-font-size m-0 flex-grow-1"
                      gutterBottom
                      variant="h4"
                      component="h4"
                      style={{ textAlign: 'justify' }}
                    >
                      Question {activeStep + 1} of {steps.length}
                    </Typography>

                    <div>
                      {
                        !openAnswerPopup &&
                        questions[activeStep].latitude._text &&
                        questions[activeStep].longitude._text ?
                            <Button className="actionButton"
                                    onClick={() => history.push({
                                      pathname: '/map',
                                      state: [{ ...questions[activeStep] }],
                                    })}>
                              <img src={require('../../images/map_pin.svg').default} alt="Map Button"/>
                            </Button> : null
                      }

                      {audioSrc && <audio controls
                             hidden
                             ref={(ref) => audioRef.current = ref}
                             src={httpsConv(audioSrc)}
                             preload="auto" />
                      }
                      {
                        !openAnswerPopup && questions[activeStep].audio_hint?._text && <Button className="actionButton"
                          onClick={() => {
                            setAudioState((prevState => {
                              if (!prevState) {
                                audioRef.current.play();
                              } else {
                                audioRef.current.pause();
                              }
                              return !audioState;
                            }));
                          }}
                        >
                          <img src={require('../../images/Audio.svg').default} alt="Audio Button" />
                        </Button>
                      }

                      {
                        openAnswerPopup && questions[activeStep].answer_audio && questions[activeStep].answer_audio._text &&
                        <Button className="actionButton"
                                onClick={() => {
                                  setAudioState((prevState => {
                                    if (!prevState) {
                                      audioRef.current.play();
                                    } else {
                                      audioRef.current.pause();
                                    }
                                    return !audioState;
                                  }));
                                }}
                        >
                          <img src={require('../../images/Audio.svg').default} alt="Audio Button" />
                        </Button>
                      }
                      {
                        questions[activeStep].youtube_link?._text &&
                        <Button className="actionButton"
                                onClick={() =>
                                    window.open(questions[activeStep].youtube_link._text, "_blank")
                                }>
                          <img src={require('../../images/Video.svg').default} alt="Video Button" />
                        </Button>
                      }

                      {
                        questions[activeStep].answer_youtube_link?._text && openAnswerPopup &&
                        <Button className="actionButton"
                                onClick={() =>
                                    window.open(questions[activeStep].answer_youtube_link._text, "_blank")
                                }>
                          <img src={require('../../images/Video.svg').default} alt="Video Button" />
                        </Button>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      { questions[activeStep].location_clue && !openAnswerPopup ? <div className="location_clue_text">
                        { questions[activeStep].location_clue._text }
                      </div> : null }

                          {questions[activeStep].picture_hint?._text && !openAnswerPopup && (
                                <CardMedia
                                    component="img"
                                    alt="Question Hint Image"
                                    className="hunt-que-img"
                                    style={{
                                      height: '400px'
                                    }}
                                    image={httpsConv(`${configData.systemUrl}${questions[activeStep].picture_hint._text}`)}
                                    title="Question Hint Image"
                                />
                            )}


                      { !openAnswerPopup ? <div className="pt-5">
                        <Typography
                            gutterBottom
                            variant="h4"
                            component="h4"
                            style={{ textAlign: 'justify', paddingBottom: '30px', fontSize: '16px', fontFamily: 'roboto' }}
                        >
                          {questions[activeStep].clue._text
                              .split('\\n')
                              .join(' ')}
                        </Typography>

                        <Typography
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: 'center',
                              marginBottom: '2rem',
                            }}
                        >
                          {questions[activeStep].option_a._text && (
                              <Button
                                  className="hunt-ans-btw"
                                  onClick={(e) =>
                                      submitanswer(e, 1, activeStep)
                                  }
                              >
                                {questions[activeStep].option_a._text}
                              </Button>
                          )}
                        </Typography>
                        <Typography
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: 'center',
                              marginBottom: '2rem',
                            }}
                        >
                          {questions[activeStep].option_b._text && (
                              <Button
                                  className="hunt-ans-btw"
                                  onClick={(e) =>
                                      submitanswer(e, 2, activeStep)
                                  }
                              >
                                {questions[activeStep].option_b._text}
                              </Button>
                          )}
                        </Typography>
                        <Typography
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: 'center',
                              marginBottom: '2rem',
                            }}
                        >
                          {questions[activeStep].option_c._text && (
                              <Button
                                  className="hunt-ans-btw"
                                  onClick={(e) =>
                                      submitanswer(e, 3, activeStep)
                                  }
                              >
                                {questions[activeStep].option_c._text}
                              </Button>
                          )}
                        </Typography>
                        <Typography
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: 'center',
                              marginBottom: '2rem',
                            }}
                        >
                          {questions[activeStep].option_d?._text && (
                              <Button
                                  className="hunt-ans-btw"
                                  onClick={(e) =>
                                      submitanswer(e, 4, activeStep)
                                  }
                              >
                                {questions[activeStep].option_d._text}
                              </Button>
                          )}
                        </Typography>
                      </div> : null }
                    </div>
                  </div>
                  {/* {getStepContent(activeStep)} */}

                  {
                    openAnswerPopup && <div>
                      <div className='answerImageContainer'>
                        <img className="answerImage"
                             src={ require(`../../images/${correct[activeStep] ? 'Correct Answer.svg' : 'Wrong Answer.svg'}`).default }
                             alt='Correct Answer'
                        />
                      </div>
                      {
                        !correct[activeStep] ? <div className="answer">
                          <h1> Correct Answer: { getCorrectAnswerText(questions[activeStep].correct_answer._text) }</h1>
                        </div> : null
                      }

                      <div className="pt-3 infoText">
                        <h3> { questions[activeStep].info._text }</h3>
                      </div>

                      <div className="text-right">
                        <Button className="btn btn-primary mt-5" onClick={() => handleNext()}>
                          <img src={require('../../images/next_arrow.png').default} alt="Next button" />
                        </Button>
                      </div>
                    </div>
                  }
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
