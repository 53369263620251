import React from 'react';
import './styles.scss';
import coinGold from '../../images/hunts/gold.svg';
import coinSilver from '../../images/hunts/silver.svg';
//import coinBronze from '../../images/hunts/bronze.svg';
import emptyBronze from '../../images/hunts/empty.svg';
const coins = {
  silver: coinSilver,
  gold: coinGold,
  none: emptyBronze,
};

export default function Coin({ type }) {
  return (
    <div className="coin-coin">
      <img src={coins[type]} alt={type} />
    </div>
  );
}
