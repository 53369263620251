import { Button } from 'react-bootstrap';
import React, { useEffect, useState, useContext, useCallback } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import dist from '@turf/distance';
import coinGold from '../images/hunts/gold.svg';
import { SupplierContext } from '../SupplierProvider';
import configData from '../config';
import { httpsConv } from '../utils';

const defaultUserPosition = [-3.201, 55.949];
const distance = (hunt, userPosition) => {
  const c1 = [parseFloat(hunt.hunt_lng._text), parseFloat(hunt.hunt_lat._text)];
  return dist(c1, userPosition, { units: 'kilometres' });
};
export function Home() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [allhunts, setAllHunts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentCount, setCurrentCount] = useState(6);
  const [userPosition, setUserPosition] = useState();

  const suppliers = useContext(SupplierContext);
  const loadMore = () => {
    setCurrentCount(currentCount + 3);
  };

  const sortByDistance = useCallback(
    (f, s) => {
      const d1 = distance(f, userPosition);
      const d2 = distance(s, userPosition);
      return d1 - d2;
    },
    [userPosition]
  );

  useEffect(() => {
    const searchvalue = localStorage.getItem('search');
    if (searchvalue) {
      setSearch(searchvalue);
    }
  }, []);

  useEffect(() => {
    if (!suppliers.loading && userPosition) {
      const filteredHunts = suppliers.suppliers.filter((obj) =>
        obj.hunt_title._text.toLowerCase().includes(search.toLowerCase())
      );
      setAllHunts(filteredHunts.sort(sortByDistance));
      setLoading(false);
    }
  }, [suppliers, search, userPosition, sortByDistance]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition([
            position.coords.longitude,
            position.coords.latitude,
          ]);
        },
        () => {
          // on error
          setUserPosition(defaultUserPosition);
        }
      );
    } else {
      setUserPosition(defaultUserPosition);
    }
  }, []);

  const SearchResult = async (value) => {
    setLoading(true);
    setSearch(value);
    localStorage.setItem('search', value);
    setLoading(false);
  };
  return (
    <div>
      <div className="edjucation-banner">
        <section className="left-right-space-new grid-none">
          <div className="container-fluid">
            <div className="search-input cus-flex-btn">
              <div className="search">
                <input
                  className="search-deign"
                  type="search"
                  placeholder="Search for a trail"
                  value={search}
                  onChange={(e) => {
                    SearchResult(e.target.value);
                  }}
                />
              </div>
              <div className="mobile-view-center-btn">
                <button
                  className="map-view-btn ml-3"
                  onClick={() => history.push('/nearby')}
                >
                  Map View
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* mobile view Button */}
        <section className=" pt-4 left-right-space-new map-view-none">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              <div className="col-10">
                <div className="search">
                  <input
                    className="search-deign"
                    type="search"
                    placeholder="Search for a trail"
                    value={search}
                    onChange={(e) => {
                      SearchResult(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <i
                  className="fas fa-globe-europe"
                  onClick={() => history.push('/nearby')}
                ></i>
              </div>
            </div>
          </div>
        </section>
        {loading ? (
          <strong>Loading...</strong>
        ) : (
          // gridView ? (
          <section className="left-right-space-new space-top-box">
            {!allhunts || allhunts.length < 1 ? (
              <div>
                <strong>No matching hunts!</strong>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={currentCount} //This is important field to render the next data
                next={loadMore}
                hasMore={currentCount < allhunts?.length}
                loader={null}
                className="row"
              >
                {allhunts.map((obj, index) => {
                  return currentCount <= index ? null : (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-space col-space-new"
                      key={obj.hunt_id}
                    >
                      <div className="card">
                        <div
                          style={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                          }}
                        >
                          {obj.hunt_thumb ? (
                            <img
                              className="imagefit img-responsive"
                              src={httpsConv(configData.systemUrl+obj.hunt_thumb._text)}
                              alt=""
                            />
                          ) : (
                            <img
                              src={coinGold}
                              className="missing-image img-responsive"
                              alt=""
                            />
                          )}
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            padding: '15px',
                            display: 'flex',
                            backgroundColor: '#E9E8DB',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            <div className="title-text-color">
                              {obj.hunt_title._text}{' '}
                              <span className="distance-text">
                                {' '}
                                {Math.round(100 * distance(obj, userPosition)) /
                                  100}{' '}
                                km
                              </span>
                            </div>
                          </div>
                          <div>
                            <Button
                              className="play-btn"
                              onClick={(e) => {
                                history.push({
                                  pathname: '/trail/' + obj.hunt_id,
                                  state: obj,
                                });
                              }}
                            >
                              {/*Play*/} <div className="fas fa-play pl-1" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            )}
          </section>
        )}
      </div>
    </div>
  );
}

export default Home;
