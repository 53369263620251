import React from 'react';
import { Link } from 'react-router-dom';
import configData from '../config';

export default function Header() {
  return (
    <div>
      <header className="container-fluid header-right">
        <div className="header-logo">
          {/*   <img src={require("../images/logo.png").default} alt="" /> */}
          <h1>{configData.title}</h1>
        </div>
        <nav className="header-text">
          <ul>
            <li>
              <Link to="/"> Home </Link>
            </li>
            <li>
              <Link to="/saved-rewards">My Rewards</Link>
            </li>
          {/*  <li>
              <Link to="/my-trails">My Trails</Link>
            </li>
            <li>
                      <Link to="/Education">
                    <a href="#"> Education </a>
                    </Link>
                  </li>
                  <li>
                      <Link to="/Build">
                    <a> Build Your Own Trail </a>
                    </Link>
                  </li>
                  <li>
                    <a href="#"> Apps </a>
                  </li>
                  <li>
                    <a href="#"> News </a>
                  </li>
                  <li>
                      <Link to="/Login">
                    <a href="#"> Login </a>
                    </Link>
                  </li>*/}
          </ul>
        </nav>
      </header>
    </div>
  );
}
