import './App.css';
import "./Component/home.scss";  // to refactor
import { BrowserRouter as Router, Route, } from 'react-router-dom';
import React from 'react';
import Home from './Component/Home';
import HuntQuestion from "./Component/HuntQuestion/HuntQuestion";
import Header from './Component/Header';

import MyTrails from "./Component/MyTrails";
import NearBy from "./Component/NearBy";
import TrailDetails from "./Component/trainDetail/trailDetail";
import SupplierProvider from './SupplierProvider';
import MapView from "./Component/Map View/mapView";
import SavedRewards from "./Component/savedRewards/savedRewards";

function App() {
  return (
    <div>
      <SupplierProvider>
        <Router>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/hunt/:huntid" component={HuntQuestion} />
        <Route exact path="/trail/:huntid" component={TrailDetails} />
        <Route exact path="/my-trails" component={MyTrails} />
        <Route exact path="/nearby" component={NearBy} />
        <Route exact path="/map" component={MapView} />
        <Route exact path="/saved-rewards" component={SavedRewards} />
        {/* Uncomment below code for extra features
        These components were removed by ZH on 22/05/2021.
        Please get them from git repo from a commit before this date
        */}
        {/* <Route exact path='/Build'component={Build}/>
        <Route exact path='/Login'component={Login}/>
        <Route exact path='/Education'component={Education}/>
        <Route exact path='/Tourism' component={Tourism}/> */}
        </Router>
      </SupplierProvider>
    </div>
  );
}

export default App;
