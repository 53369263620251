import React, { useEffect, useState, useMemo } from 'react';
import configData from './config';
import { ApiGet } from './utils';

export const SupplierContext = React.createContext();

function SupplierProvider({ children }) {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const key = (configData.site || '')+'suppliers';
      const store = localStorage.getItem(key);
      const timeout = 60 * 60 * 1000; //60 minutes timeout
      const storedSuppliers = store && JSON.parse(store);
      const selectedSuppliers = configData.suppliers;

      let hunts = [];

      const addHunt = (hunt, sup) => {
        hunts.push({
          ...hunt,
          supplier_info: sup.supplier_info._text,
          hunt_id: hunt.hunt_id._text,
        });
      };
      if (
        !storedSuppliers?.date ||
        !storedSuppliers?.hunts ||
        storedSuppliers?.date + timeout < Date.now()
      ) {
        // reload if expired
        console.log('Reload data');
        let res = await ApiGet(`${configData.apiUrl}/suppliers.xml`);

        var convert = require('xml-js');
        var result1 = convert.xml2json(res.data, { compact: true, spaces: 2 });
        let supplier = JSON.parse(result1).suppliers;

        supplier.supplier.forEach((sup) => {
          const supplierID=parseFloat(sup.supplier_id._text,10);
          if (!selectedSuppliers || selectedSuppliers.find(i=>i===supplierID)) { /// add olnly selected suppliers


          if (sup.hunts?.hunt?.length > 0) {
            sup.hunts.hunt.forEach((hunt) => addHunt(hunt, sup));
          } else if (sup.hunts?.hunt?.hunt_id) {
            addHunt(sup.hunts.hunt, sup);
          } else {
            // console.log('Suplier has not hunt',sup);
          }
        }
        });
        localStorage.setItem(
          key,
          JSON.stringify({ hunts, date: Date.now() })
        );
        setSuppliers(hunts);
      } else {
        console.log('Resuse data');
        setSuppliers(storedSuppliers.hunts);
      }
      setLoading(false);
    })();
  }, []);

  const state = useMemo(
    () => ({
      loading,
      suppliers,
    }),
    [loading, suppliers]
  );

  return (
    <SupplierContext.Provider value={state}>
      {children}
    </SupplierContext.Provider>
  );
}

export default SupplierProvider;
