import React from 'react';
import './styles.scss';
import Coin from '../Coin';
export default function CoinCollection({ answers, current }) {
  const coinWidth = `${100/answers.length}%`;  // we want coind to fill the active screen width
  return (
    <div className="coin-collection-coins">
      {answers.map((correct, index) => (
        <div style={{ width:coinWidth }} key={index}>
          <Coin
            type={index < current ? (correct ? 'gold' : 'silver') : 'none'}
          />
        </div>
      ))}
    </div>
  );
}
