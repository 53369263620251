//import configData from "./config";

//const axios = require("axios");
const axios = require("axios").default;


/*
const instance = axios.create({
  baseURL: configData.apiUrl,
  dataType: 'xml',
  timeout: 1000,
  headers: {
'Access-Control-Allow-Origin': '*',
"Referrer-Policy": "no-referrer"

  },

});
sds
//baseURL: 'http://api.my-awesome-app.com'
*/

export const ApiGet = (type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(type, {
          isJsonRequest: 'application/xml; charset=utf-8',
          api_key: '6QSy49rUTH'
        })
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          if (
            error &&
            error.hasOwnProperty('response') &&
            error.response &&
            error.response.hasOwnProperty('data') &&
            error.response.data &&
            error.response.data.hasOwnProperty('error') &&
            error.response.data.error
          ) {
            reject(error.response.data.error);
          } else {
            reject(error);
          }
        });
    });
  };

  export const httpsConv = (url) => {
    if (url &&  url?.slice(0,6).toLowerCase()==="http:/" ) {
      return url.slice(0, 4) + 's' + url.slice(4);
    }
    return url;
  }