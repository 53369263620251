import { Button } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";

import { httpsConv } from "../utils";
import { SupplierContext } from '../SupplierProvider';
import configData from '../config';


export function NearBy(props) {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [allhunts, setAllHunts] = useState();
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({
    lat: 55.949,
    lng: -3.201,
  });
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const suppliers = useContext(SupplierContext);
  // const [gridView, setGridView] = useState(true);


  useEffect(() => {
    const searchvalue = localStorage.getItem('search');
    if (searchvalue) {
      setSearch(searchvalue);
    }
  }, []);

  useEffect(() => {
    if (!suppliers.loading) {
      const filteredHunts = suppliers.suppliers.filter((obj) =>
        obj.hunt_title._text.toLowerCase().includes(search.toLowerCase())
      );
      setAllHunts(filteredHunts);
      setLoading(false);
    }
  }, [suppliers, search]);


  useEffect( () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
   /* navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state === "denied") {
        setCenter({
          lat: 55.949,
          lng: -3.201,
        });
      }
    });*/
  }, []);



  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClicked = (props) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
      setSelectedPlace();
    }
  };

  function onInfoWindowOpen(e, selectedPlace) {
    // const button = (<button onClick={e => {console.log("hmapbuttoni1");}}>mapbutton</button>);
    const iwc = document.getElementById("iwc");
    if (iwc) {
      iwc.addEventListener("click", function () {
        // console.log("HUnts:::::",selectedPlace)
        if (selectedPlace && selectedPlace.huntid) {
          history.push("/hunt/" + selectedPlace.huntid);
        }
      });
    }
  }
  const SearchResult = async (value) => {
    setLoading(true);
    setSearch(value);
    localStorage.setItem('search', value);
    setLoading(false);
  };

  return (
    <div>
      <div className="edjucation-banner">
        <section className="left-right-space-new grid-none">
          <div className="container-fluid">
            <div className="search-input cus-flex-btn">
              <div className="search">
                <input
                  className="search-deign"
                  type="search"
                  placeholder="Search for a trail"
                  value={search}
                  onChange={(e) => {
                    SearchResult(e.target.value);
                  }}
                />
              </div>
              <div className="mobile-view-center-btn">
                <button className="map-view-btn ml-3" onClick={() => history.push("/")}>
                  Grid View
                </button>
              </div>

              {/* <div className="file">
                <img src={require("../images/folder.svg").default} />
                <sapn className="folder-style"> Create a folder </sapn>
              </div> */}
            </div>
          </div>
        </section>

        {/* mobile view Button */}
        <section className=" pt-4 left-right-space-new map-view-none">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              <div className="col-10">
              <div className="search">
                <input
                  className="search-deign"
                  type="search"
                  placeholder="Search for a trail"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              </div>
              <div className="col-2">
              <i className="fas fa-th" onClick={() => history.push("/")}></i>
              </div>
            </div>
          </div>
        </section>


        {loading ? (
          <strong>Loading...</strong>
        ) :
        (
          <section className="space-top-box">
            <>
              {center && (
                <Map google={props.google} containerStyle={{ backgroundColor: "#ffffff", padding: "1em", height: `calc(100vh - 190px)` }} style={{ width: "100%" }} onClick={onMapClicked} initialCenter={center} zoom={12}>
                  {allhunts && allhunts.length > 0 ? (
                    allhunts.map((obj, index) => {
                      return <Marker key={index} onClick={onMarkerClick} id="your_location" name={obj.hunt_title._text + " (Hunt)"} huntid={obj.hunt_id._text} title="hunt" image={obj.hunt_photo && httpsConv(obj.hunt_photo._text)} position={{ lat: obj.hunt_lat._text, lng: obj.hunt_lng._text }} />;
                    })
                  ) : (
                    <div>
                      <h6>No Hunts Found</h6>
                    </div>
                  )}

                  <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}
                    onOpen={(e) => {
                      onInfoWindowOpen(e, selectedPlace);
                    }}
                  >
                    <div className="col-md-12 col-space col-space-new" style={{ padding: "0px" }}>
                      <div className="card">
                        <div style={{ width: "100%", height: "250px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>{selectedPlace && selectedPlace.image ? <img src={selectedPlace.image} className="img-responsive img-fluid" style={{ height: "inherit" }} alt="Not Found" /> : <p>No Image Found</p>}</div>
                        <div className="row d-flex align-items-center" style={{ fontWeight: "bold", padding: "15px", display: "flex", backgroundColor: "white", justifyContent: "space-between", margin: "0px" }}>
                          <div className="col-9">
                            <div className="title-text-color">{selectedPlace && selectedPlace.name}</div>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <Button className="play-btn" id="iwc">
                              <i className="fas fa-play pl-1"></i>
                            </Button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </InfoWindow>
                </Map>
              )}
            </>
          </section>
        )
        }
      </div>
    </div>
  );
}
export default GoogleApiWrapper({
  apiKey: configData.googleApiKey,
})(NearBy);
// export default Education;
