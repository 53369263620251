import {Grid} from "@material-ui/core";
import React from "react";
import './savedRewards.scss';

export default function SavedRewards() {

    let savedRewards = [];
    try {
        savedRewards = JSON.parse(localStorage.getItem('savedRewards'));
    } catch (e) {}

    const getRewardImageByScore = (score) => {
        if (score > 0.8) {
            return require('../../../src/images/hunts/gold.svg').default;
        } else if (score >= 0.5) {
            return require('../../../src/images/hunts/silver.svg').default;
        } else {
            return require('../../../src/images/hunts/bronze.svg').default;
        }
    };

    const getRewardIBadgeName= (score) => {
        if (score > 0.8) {
            return 'Gold badge';
        } else if (score >= 0.5) {
            return 'Silver badge';
        } else {
            return 'Bronze badge';
        }
    };

    return (
        <div className="container-fluid maxWidhCointainer">
            <div className="mt-3 d-flex justify-content-center hunt-title text-center">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1>My Reward</h1>
                    </Grid>

                    {
                        savedRewards && savedRewards.length ? savedRewards.map((reward) => {
                            return <Grid item xs={6} className='text-center' key={reward.huntTitle}>
                                <img
                                    src={getRewardImageByScore(reward.score)}
                                    style={{ maxWidth: '264px' }}
                                    className="d-inline-block"
                                    alt={getRewardIBadgeName(reward.score)} />
                                <h2 className="pt-3 huntTitle">{reward.huntTitle}</h2>
                            </Grid>
                        }) :
                        <Grid item xs={12}>
                                <div className="text-center">
                                    <h4>No rewards yet. Start a new trail!</h4>
                                </div>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
    );
};

