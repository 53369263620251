import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {  GoogleApiWrapper } from "google-maps-react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    margin: theme.spacing(2),
    // marginBottom: theme.spacing(1),
  },
}));

export function MyTrails(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [hunts, setHunts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTrail, setSelectedTrail] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const classes = useStyles();

  const deleteHunt = (obj) => {
    setLoading(true);
    const items = hunts;
    let index = items.findIndex((x) => x.hunt.hunt_id === obj.hunt.hunt_id);
    // console.log(object)
    if (index !== -1) {
      items.splice(index, 1);
      setHunts(items);
      localStorage.setItem("mytrails", JSON.stringify(items));
    }
    setLoading(false);
  };

  useEffect( () => {
    setLoading(true);

    (async () => {
    const huntsData = localStorage.getItem("huntsData");
    let huntsData1
    if (huntsData) {
      huntsData1= JSON.parse(huntsData);
    }

    const item = localStorage.getItem("mytrails");

    if (item) {
      let items = JSON.parse(item);
      let data = items.map((obj)=>{
        let value = huntsData1.find((m)=>m.hunt_id === obj.hunt.hunt_id)
        obj.huntData = value
        return obj
      })
      console.log("data",data)
      setHunts(data);
    }

    setLoading(false);
  })();
  }, []);

  return (
    <div>
      <div className="edjucation-banner">
        <section className="left-right-space-new">
          <div className="container-fluid">
            <div className="search-input">
              <div className="search">
                <h1>My Trails</h1>
              </div>
            </div>
          </div>
        </section>
        {loading ? (
          <strong>Loading...</strong>
        ) : (
          <section className="left-right-space-new space-top-box">
            <div className="container-fluid">
              <div className="row">
                {hunts.length > 0 ? (
                  hunts.map((obj, index) => {
                    return (
                      <div className="col-md-4 col-space col-space-new" key={index}>
                        <div className="card" style={{ marginBottom: "20px" }}>
                          <div style={{ width: "100%", height: "300px", display:"flex",alignItems:"center", justifyContent: "center", backgroundColor: "antiquewhite" }}>
                          {obj.huntData.hunt_photo ? <img src={obj.huntData.hunt_photo._text} className="img-responsive img-fluid" style={{ height: "inherit" }} alt="Not Found" /> : <p>No Image Found</p>}
                          </div>
                          <div style={{ fontWeight: "bold", padding: "15px", display: "flex", backgroundColor: "#ffae15", justifyContent: "space-between" }}>
                            <div>
                              <div style={{fontSize:"17px"}}>Title : {obj.hunt.hunt_title._text}</div>
                            </div>
                          </div>
                          <div style={{ fontWeight: "bold", padding: "15px", display: "flex", backgroundColor: "#ffae15", justifyContent: "space-between" }}>
                            <div>
                              <Button variant="success"  style={{fontSize:"17px"}}
                                onClick ={(e)=>{
                                  history.push("/hunt/" + obj.hunt.hunt_id);
                                }}
                              >Play </Button>
                            </div>
                            <div>
                              <Button
                                variant="info" style={{fontSize:"17px"}}
                                onClick={(e) => {
                                  setSelectedTrail(obj);
                                  handleShow(e);
                                }}
                              >
                                My Answer{" "}
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="danger" style={{fontSize:"17px"}}
                                onClick={(e) => {
                                  e.preventDefault();
                                  deleteHunt(obj);
                                }}
                              >
                                Delete{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <strong>No Record Found</strong>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hunts: {selectedTrail && selectedTrail.hunt && selectedTrail.hunt.hunt_title._text}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={3}>
              {selectedTrail &&
                selectedTrail.hunt &&
                selectedTrail.hunt.questions.question.map((obj, index) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <Card key={index} >
                        <CardContent>
                          <Typography gutterBottom variant="h4" component="h2" style={{ textAlign: "center" }}>
                            Question {index + 1}: {obj.clue._text}
                          </Typography>
                          <Typography gutterBottom variant="h6" component="h6" style={{ textAlign: "center" }}>
                            {obj.location_clue._text}
                          </Typography>
                          <div className="pt-3">
                            <Typography gutterBottom variant="h6" component="h2" style={{ textAlign: "center", justifyContent: "space-around", display: "flex", alignItems: "center" }}>
                             <div> Your Answer: {selectedTrail.answers[index]}
                             </div><div>
                              Correct Answer: {obj.correct_answer._text}</div>
                            </Typography>
                          </div>

                          <div className="pt-1">
                            <Typography gutterBottom variant="h6" component="h2" style={{ textAlign: "center" }}>
                              Solution: {obj.correct_answer._text === "1" ? obj.option_a._text : obj.correct_answer._text === "2" ? obj.option_b._text : obj.option_c._text}
                            </Typography>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
            <Card className={classes.instructions}>
              <CardContent>
                <Typography gutterBottom variant="h1" component="h2" style={{ textAlign: "center" }}>
                  Your Score: {selectedTrail &&
                selectedTrail.hunt &&
                selectedTrail.hunt.questions.question.filter((obj, index) => parseInt(obj.correct_answer._text) === selectedTrail.answers[index]).length} / {selectedTrail && selectedTrail.answers.length}
                </Typography>
              </CardContent>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBp6ESgNz33wpDvKcaAI3VFSVqiZcQChJQ",
})(MyTrails);
