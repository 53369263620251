import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import configData from '../../config';

export function MapView(props) {

    const history = useHistory();
    const [center, setCenter] = useState({
        lat: 55.949,
        lng: -3.201,
    });
    const huntQuestions = props.location.state;
    let refMap = React.createRef();
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [selectedPlace, setSelectedPlace] = useState({});

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter({lat: position.coords.latitude, lng: position.coords.longitude});
            });
        }
       /* navigator.permissions.query({name: "geolocation"}).then(function (result) {
            if (result.state === "denied") {
                setCenter({
                    lat: 55.949,
                    lng: -3.201,
                });
            }
        });*/
    }, []);

    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    const onMapClicked = (props) => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
            setSelectedPlace();
        }
    };

    function onInfoWindowOpen(e, selectedPlace) {
        // const button = (<button onClick={e => {console.log("hmapbuttoni1");}}>mapbutton</button>);
        const iwc = document.getElementById("iwc");
        if (iwc) {
            iwc.addEventListener("click", function () {
                // console.log("HUnts:::::",selectedPlace)
                if (selectedPlace && selectedPlace.huntid) {
                    history.push("/hunt/" + selectedPlace.huntid);
                }
            });
        }
    }


    const centerP = {lat: 0, lng:0};
    let count = 0;
    if (huntQuestions?.length>0)
    huntQuestions.forEach(question => {

        if (question.latitude._text && question.longitude._text)
        {
            centerP.lat += parseFloat(question.latitude._text);
            centerP.lng += parseFloat(question.longitude._text);
            count++;
        }

    });
    if (count>0) {
        centerP.lat /= count;
        centerP.lng /= count;
    };
    const createMapOptions =   (maps) =>
        ( {
          panControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
        }
        );

    return (
        <>
            {center && <Map google={props.google}

options={createMapOptions}
                            ref={refMap}
                            onClick={onMapClicked}

                            containerStyle={{backgroundColor: "#ffffff", padding: "1em", height: `calc(100vh - 75px)`}}
                          //  onBoundsChanged={handleBoundsChanged}
                            style={{width: "100%"}}
                             //center={center}
                             initialCenter={centerP}
                            zoom={12}


                             >
                {huntQuestions && huntQuestions.length > 0 ? (
                    huntQuestions.map((obj, index) => {
                        return <Marker key={index} onClick={onMarkerClick} id="your_location" name={'marker' + index} title="Question" position={{ lat: obj.latitude._text, lng: obj.longitude._text }} />;
                    })
                ) : (
                    <div>
                        <h6>No Data Found</h6>
                    </div>
                )}

                <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}
                    onOpen={(e) => {
                        onInfoWindowOpen(e, selectedPlace);
                    }}
                >
                    <div className="col-md-12 col-space col-space-new" style={{ padding: "0px" }}>
                        <div className="card">
                            <div style={{ width: "100%", height: "250px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "antiquewhite" }}>{selectedPlace && selectedPlace.image ? <img src={selectedPlace.image} className="img-responsive img-fluid" style={{ height: "inherit" }} alt="Not Found" /> : <p>No Image Found</p>}</div>
                            <div className="row d-flex align-items-center" style={{ fontWeight: "bold", padding: "15px", display: "flex", backgroundColor: "white", justifyContent: "space-between", margin: "0px" }}>
                                <div className="col-9">
                                    <div className="title-text-color">{selectedPlace && selectedPlace.name}</div>
                                </div>
                                <div className="col-3 d-flex justify-content-end">
                                    <Button className="play-btn" id="iwc">
                                        Play <i className="fas fa-play pl-1"></i>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </InfoWindow>
            </Map>}
        </>
    );
}
export default GoogleApiWrapper({
    apiKey: configData.googleApiKey,
})(MapView);
