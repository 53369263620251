import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import 'sweetalert2/src/sweetalert2.scss';
import './trailDetails.scss';
import {ApiGet, httpsConv} from "../../utils";
import configData from "../../config";
import coinGold from "../../images/hunts/gold.svg";

export default function TrailDetails(props) {
    const history = useHistory();
    const trail = props.location.state;
   // console.log('trailData', trail)
    const [questionCoordinates, setQuestionCoordinates] = useState([]);
    const {huntid} = useParams();

    useEffect(() => {
        (async () => {
            let res = await ApiGet(`${configData.apiUrl}/hunts/${huntid}.xml`);
            var convert = require('xml-js');
            var result1 = convert.xml2json(res.data, { compact: true, spaces: 2 });
            let hunt = JSON.parse(result1).hunt;
            setQuestionCoordinates(hunt.questions.question);
        })();
    }, [huntid]);

    return (
        <div className="container-fluid maxWidhCointainer">
            <div className="trailImage">
                {trail.hunt_thumb ? (
                    <img
                        className="imagefit img-responsive"
                        src={httpsConv(configData.systemUrl+trail?.hunt_thumb?._text)}
                        alt=""
                    />
                ) : (
                    <img src={coinGold}  className="missing-image img-responsive"  alt="" />
                )}
            </div>

            <div className="">

                <h1 className="hunt_title">
                    <span>{ trail?.hunt_title._text }</span>
                </h1>

                <div className="d-flex w-100 initiateQuestionButton">
                    <span className={`d-flex align-items-center ${trail?.hunt_lat._text && trail?.hunt_lng._text ? 'cursor-pointer' : 'cursor-disable'}`} onClick={() => {
                        if (trail?.hunt_lat._text && trail?.hunt_lng._text) {
                            history.push({
                                pathname: '/map',
                                state: questionCoordinates,
                            });
                        }
                    }}>
                        <img src={require('../../images/Map Glyph.svg').default} className="mapIcon" alt={'Map'}/>
                        <h1 className="m-0">Map view </h1>
                    </span>
                    <span className="flex-grow-1">&nbsp;</span>
                    <Button className="play-btn" onClick={() => history.push('/hunt/' + huntid)}>
                      <i className="fas fa-play play-button "/>
                    </Button>
                </div>

                <div className="supplierInfo">{ trail.supplier_info }</div>
            </div>
        </div>
    );
}
